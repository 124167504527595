import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class Section extends Component {
  render() {
    return (
        <React.Fragment>
          <section
              className="section bg-home home-half home-registration"
              id="home"
          >
            <div className="bg-overlay"></div>
            <div className="display-table">
              <div className="display-table-cell">
                <Container>
                  <Row className="vertical-content">
                    <Col lg={7} className="text-white text-left mt-4">
                      <h1 className="home-title">
                        Helping Clients Achieve Desired Results
                      </h1>
                      <p className="pt-3 home-desc home-subtitle-width-100">
                        Stellartek Ltd is an IT Consultancy provider specialising in cloud solutions for the enterprise.
                      </p>
                      <p className="pt-3 home-desc home-subtitle-width-100">
                        The company has been taken part in delivering high-volume, mission-critical cloud based applications to blue-chip companies in the UK.
                      </p>
                      <p className="pt-3 home-desc home-subtitle-width-100">
                        Our aim is to help clients achieve their business objectives by providing best-in-class consulting, IT solutions and services in a cost effective way.
                      </p>

                    </Col>

                  </Row>
                </Container>
              </div>
            </div>
          </section>
        </React.Fragment>
    );
  }
}

export default Section;
