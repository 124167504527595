import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Footer link
import Switcher from "./Switcher";

class Footer extends Component {
  state = {
    links: [
      {
        title: "Company",
        child: [
          { link: "#", title: "Home" },
          { link: "#", title: "About us" },
          { link: "#", title: "Careers" },
          { link: "#", title: "Contact us" },
        ],
      },
      {
        title: "Information",
        child: [
          { link: "#", title: "Terms & Condition" },
          { link: "#", title: "About us" },
          { link: "#", title: "Jobs" },
          { link: "#", title: "Bookmarks" },
        ],
      },
      {
        title: "Support",
        child: [
          { link: "#", title: "FAQ" },
          { link: "#", title: "Contact" },
          { link: "#", title: "Disscusion" },
        ],
      },
      {
        title: "Blog  ",
        child: [
          { link: "#", title: "All Articles" },
          { link: "#", title: "Collaborators" }
        ],
      }
    ],
  };
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <Container>
            <Row>
              {this.state.links.map((fLink, key) => (
                <Col key={key} lg={3} className="mt-4">
                  <h4>{fLink.title}</h4>
                  <div className="text-muted mt-4">
                    <ul className="list-unstyled footer-list">
                      {fLink.child.map((fLinkChild, key) => (
                        <li key={key}>
                          <Link to={fLinkChild.link}>{fLinkChild.title}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              ))}


            </Row>
          </Container>
        </footer>

        {/* color switcher */}
        <Switcher />
      </React.Fragment>
    );
  }
}

export default Footer;
